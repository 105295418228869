<template>
  <div class="sliderVerification">
    <Verify
      @success="verifySuccess"
      :mode="'pop'"
      :captchaType="cap_type"
      :imgSize="{ width: '330px', height: '155px' }"
      ref="verify"
    />
  </div>
</template>

<script>
import Verify from '../verifition/Verify'
export default {
  name: 'sliderVerification',
  components: {
    Verify
  },
  data() {
    return {
      cap_type: "",
    }
  },
  mounted() {
    this.cap_type = "blockPuzzle";
    this.$refs.verify.show();
  },
  methods: {
    verifySuccess(params) {
      let deviceType = this.$store.state.deviceType;
      if(deviceType == 1){
        window.webkit.messageHandlers.VerSuccess.postMessage('success');
      }else if(deviceType == 2){
        window.Android.VerSuccess('success');
      }
    },
  },
}
</script>

<style lang='scss'>
  .sliderVerification {
    height: 100%;
    width: 100%;
    background-color: transparent;
  }
</style>